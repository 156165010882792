document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector('body');
    const burger = document.querySelector('.navbar__burger');
    const burgerMenu = document.querySelector('.navbar__menu');
    const select = document.querySelectorAll('.select[data-type]');
    const options = document.querySelector('.options')

    const hideBurgerMenu = () => {
        burgerMenu.className = 'navbar__menu';
        burger.className = 'navbar__burger';
        body.classList.remove('overflow')
    };

    const hideDropdown = () => {
        select.forEach(elem=> {
            elem.classList.remove('active')
        })
    }

    body.addEventListener('click', (e) => {
        const targetClasses = e.target.classList;
        console.log(targetClasses)
        if (!targetClasses.contains('navbar__burger') && !targetClasses.contains('navbar__menu')&& !targetClasses.contains('lang')) {
            hideBurgerMenu();
        }
        if(!targetClasses.contains('select')) {
            hideDropdown()
        }
        if(targetClasses.contains('filter__btn')) {
            options.classList.toggle('active')
        }
    });

    const initHoverLink = () => {
        const mobileSidebar = document.querySelector('.mobile__group');
        const phone = document.querySelector('.mobile__phone');
        mobileSidebar && mobileSidebar.addEventListener('mouseover', (e) => {
            if(e.target.classList.contains('mobile__item')) {
                phone.classList.toggle('active')
            }
            if(e.relatedTarget && e.relatedTarget.classList.contains('mobile__item')) {
                phone.classList.toggle('active')
            }
        })
    }

    const initBurgerMenu = () => {
        burger.addEventListener('click', (e)=>{
            if(e.target.classList.contains('navbar__burger')) {
                burgerMenu.classList.toggle('active');
                burger.classList.toggle('active');
                body.classList.add('overflow')
            }
        });

        burgerMenu.addEventListener('click', (e) => {
            const burgerItem = e.target;
            if(burgerItem.classList.contains('navbar__link')) {
                hideBurgerMenu();
            }
        });
    }

    const initDropdown = () => {
        select.forEach(elem=> {
            elem.addEventListener('click', (e) => {
                let type = elem.dataset.type;
                let dropdownValue = document.querySelector(`.select__current[data-type='${type}']`)

                elem.classList.toggle('active')
                if(e.target.closest('.select__list') && !e.target.classList.contains('select__list')){
                    dropdownValue.innerHTML = e.target.innerHTML
                }
            })
        })
    }

     const initSlider = () => {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        const slider = document.getElementById("myRange");
        const output = document.getElementById("demo");

        if (slider) {
            output.innerHTML = parseInt(slider.value, 10).toLocaleString();

            if (isIE11) {
                slider.onchange = (e) => {
                    const value = parseInt(e.target.value,10)
                    output.innerHTML = value.toLocaleString();
                }
            } else {
                slider.oninput = (e) => {
                    const value = parseInt(e.target.value,10)
                    output.innerHTML = value.toLocaleString();
                }
            }
        }
    }

    const choiceSwiper = new Swiper('.choice__swiper', {
        slidesPerView: 2,
        loop: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1.3
            },
            576: {
                slidesPerView: 1.7
            },
            768: {
                spaceBetween: 30,
                slidesPerView: 2
            },
            992: {
                spaceBetween: 40,
            },
            1200: {
                spaceBetween: 60
            }
        }
    })

    const newsSwiper = new Swiper('.news__swiper', {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlidesBounds: true,
        grabCursor: true,
        loop: true,

        navigation: {
            nextEl: '.news__next',
            prevEl: '.news__prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 3
            },
            576: {
                slidesPerView: 2
            }
        }
    })

    const awardsSwiper = new Swiper('.awards__swiper', {
        spaceBetween: 20,
        grabCursor: true,
        loop: false,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerColumn: 3,
                slidesPerView: 1,
                loop: false,
            },
            768: {
                slidesPerColumn: 1,
                slidesPerView: 2,
                loop: true
            },
            992: {
                slidesPerView: 3,
                loop: true
            }
        }
    })

    initHoverLink();
    initBurgerMenu();
    initSlider();
    initDropdown();
});
